<template>
  <div>
    <v-row>
      <v-col></v-col>
      <v-col class="text-right font-size-12"><strong>New value</strong></v-col>
      <v-col class="text-right font-size-12"><strong>Old value</strong></v-col>
    </v-row>

    <v-row v-if="showTimePoint('bolus_breakfast')">
      <v-col>
        <h6 class="d-flex align-center">
          <BolusTimepointIcon time-point="bolus_breakfast" />
          {{ $t('time-point.bolus_breakfast') }}
        </h6>
        <div class="data-line">Insulin-to-carb ratio</div>
        <div class="data-line">Insulin sensitivity factor</div>
      </v-col>
      <v-col class="text-right data-line mt-6">
        <strong class="d-block">{{
          getNewInsulinToCarb('bolus_breakfast')
        }}</strong>
        <strong class="d-block">{{
          getNewInsulinSensitivityFactor('bolus_breakfast')
        }}</strong>
      </v-col>
      <v-col class="text-right data-line mt-6">
        <div>{{ getLastInsulinToCarb('bolus_breakfast') }}</div>
        <div>{{ getLastInsulinSensitivityFactor('bolus_breakfast') }}</div>
      </v-col>
    </v-row>

    <v-row v-if="showTimePoint('bolus_lunch')">
      <v-col>
        <h6 class="d-flex align-center">
          <BolusTimepointIcon time-point="bolus_lunch" />
          {{ $t('time-point.bolus_lunch') }}
        </h6>
        <div class="data-line">Insulin-to-carb ratio</div>
        <div class="data-line">Insulin sensitivity factor</div>
      </v-col>
      <v-col class="text-right data-line mt-6">
        <strong class="d-block">{{
          getNewInsulinToCarb('bolus_lunch')
        }}</strong>
        <strong class="d-block">{{
          getNewInsulinSensitivityFactor('bolus_lunch')
        }}</strong>
      </v-col>
      <v-col class="text-right data-line mt-6">
        <div>{{ getLastInsulinToCarb('bolus_lunch') }}</div>
        <div>{{ getLastInsulinSensitivityFactor('bolus_lunch') }}</div>
      </v-col>
    </v-row>

    <v-row v-if="showTimePoint('bolus_dinner')">
      <v-col>
        <h6 class="d-flex align-center">
          <BolusTimepointIcon time-point="bolus_dinner" />
          {{ $t('time-point.bolus_dinner') }}
        </h6>
        <div class="data-line">Insulin-to-carb ratio</div>
        <div class="data-line">Insulin sensitivity factor</div>
      </v-col>
      <v-col class="text-right data-line mt-6">
        <strong class="d-block">{{
          getNewInsulinToCarb('bolus_dinner')
        }}</strong>
        <strong class="d-block">{{
          getNewInsulinSensitivityFactor('bolus_dinner')
        }}</strong>
      </v-col>
      <v-col class="text-right data-line mt-6">
        <div>{{ getLastInsulinToCarb('bolus_dinner') }}</div>
        <div>{{ getLastInsulinSensitivityFactor('bolus_dinner') }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BolusTimepointIcon from '../../BolusTimepointIcon'
export default {
  name: 'ConfirmAdjustments',
  components: { BolusTimepointIcon },
  props: {
    previewResponse: { type: Object, required: true },
    apiResponse: { type: Object, required: true },
  },
  methods: {
    getLastPrescribedCandidate(timePoint) {
      return this.apiResponse.doses.find(
        x => x.lastPrescribedDose?.timepoint === timePoint
      )
    },
    showTimePoint(timePoint) {
      const newCandidate = this.previewResponse.doses.find(
        x => x.timepoint === timePoint
      )
      const lastCandidate = this.getLastPrescribedCandidate(timePoint)

      if (!newCandidate && !lastCandidate) return false
      if (
        !newCandidate &&
        lastCandidate &&
        !lastCandidate.lastPrescribedDose?.isActive
      ) {
        return false
      }

      if (newCandidate && !newCandidate.isActive && !lastCandidate) return false

      return !(
        newCandidate &&
        lastCandidate &&
        !newCandidate.isActive &&
        !lastCandidate.lastPrescribedDose?.isActive
      )
    },
    getNewInsulinToCarb(timePoint) {
      const candidate = this.previewResponse.doses.find(
        x => x.timepoint === timePoint
      )
      if (!candidate || !candidate.isActive) return '—'
      return `${candidate.insulinToCarbRatio} g`
    },
    getNewInsulinSensitivityFactor(timePoint) {
      const candidate = this.previewResponse.doses.find(
        x => x.timepoint === timePoint
      )
      if (!candidate || !candidate.isActive) return '—'
      return `${candidate.insulinSensitivityFactor} ${this.previewResponse.bloodGlucoseUnit}`
    },
    getLastInsulinToCarb(timePoint) {
      const candidate = this.getLastPrescribedCandidate(timePoint)
      if (!candidate || !candidate.lastPrescribedDose.isActive) return '—'

      return `${candidate.lastPrescribedDose.insulinToCarbRatio} g`
    },
    getLastInsulinSensitivityFactor(timePoint) {
      const candidate = this.getLastPrescribedCandidate(timePoint)
      if (!candidate || !candidate.lastPrescribedDose.isActive) return '—'
      return `${candidate.lastPrescribedDose.insulinSensitivityFactor} ${this.apiResponse.bloodGlucoseUnit}`
    },
  },
}
</script>

<style lang="scss" scoped>
.data-line {
  font-size: 16px;
  line-height: 24px;
}
</style>
